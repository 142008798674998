<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addDevice">添加虫害预警</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 1vw">
              <el-input v-model="searchName" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
        <el-button class="upDate" type="success" size="small" icon="el-icon-refresh" @click="update"></el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="name" label="虫害名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="deviceNumber" label="设备编号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="earlyCount" label="阈值" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="sendType" label="推送类型" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.sendType == 0">手机</p>
          <p v-if="scope.row.sendType == 1">邮箱</p>
          <p v-if="scope.row.sendType == 2">手机+邮箱</p>
        </template>
      </el-table-column>
      <el-table-column prop="serial" label="推送地址" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.email">{{ scope.row.email }}</p>
          <p v-if="scope.row.phone">{{ scope.row.phone }}</p>
          <p v-if="scope.row.phone&&scope.row.email"></p>
        </template>
      </el-table-column>
      <el-table-column prop="runType" label="推送状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.runType" @change="changeState(scope.row.id)"  :active-value="1" :inactive-value="2" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!--          <el-button @click="show(scope.row)" type="success" size="small">详情</el-button>-->
          <!--          <el-button @click="edit(scope.row)" type="success" size="small">编辑</el-button>-->
          <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加   编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="虫类名称" prop="name">
            <el-input v-model="addForm.name" placeholder="请输入虫类名称"></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNumber">
            <el-select v-model="addForm.deviceNumber" placeholder="请选择设备编号">
              <el-option v-for="(item,i) in Lists"
                         :key="i"
                         :label="item.deviceNumber"
                         :value="item.deviceNumber">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预警阈值" prop="earlyCount">
            <el-input v-model="addForm.earlyCount" placeholder="请选择预警阈值"></el-input>
          </el-form-item>
          <el-form-item label="推送类型" prop="sendType">
            <el-select v-model="addForm.sendType" placeholder="请选择推送类型" @change="selType">
              <el-option v-for="(item, i) in emailType" :key="i" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="推送手机号" prop="phone" v-if="showPhone">
            <el-input type="number" v-model="addForm.phone" placeholder="请输入推送手机号"></el-input>
          </el-form-item>
          <el-form-item label="推送邮箱" prop="email" v-if="showEmail">
            <el-input type="text" v-model="addForm.email" placeholder="请输入推送邮箱"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag" style="margin-top: 2vh">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    const checkPhone = (rule, value, callback)=>{
      const phoneMsg = /^[1][3,5,7,8,9][0-9]{9}$/;
      if (!value){
        return callback(new Error('推送手机号不可为空'))
      }
      setTimeout(()=>{
        if (phoneMsg.test(value)){
          callback()
        }else{
          callback(new Error('手机格式不正确'))
        }
      },100)
    }
    const checkEmail = (rule, value, callback)=>{
      const emailMsg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if (!value){
        return callback(new Error('推送邮箱不可为空'))
      }
      setTimeout(()=>{
        if (emailMsg.test(value)){
          callback()
        }else{
          callback(new Error('邮箱格式不正确'))
        }
      },100)
    }
    return {
      value1: "",
      tableData: [],
      emailType: [
        {name: '手机', id: 0},
        {name: '邮箱', id: 1},
        {name: '手机+邮箱', id: 2}

      ],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      formTitle: '',
      addForm: {},
      rules: {
        name: [{required: true, message: "虫类名称不可为空", trigger: ["blur", "change"]}],
        deviceNumber: [{required: true, message: "设备编号不可为空", trigger: ["blur", "change"]}],
        earlyCount: [{required: true, message: "预警阈值不可为空", trigger: ["blur", "change"]}],
        sendType: [{required: true, message: "推送类型发不可为空", trigger: ["blur", "change"]}],
        email: [{required: true,  validator: checkEmail, trigger: ["blur", "change"]},],
        phone: [{required: true, validator:checkPhone,trigger: ["blur", "change"]}],
      },
      messageBox: false,
      showFlag: false,
      value: true,
      Lists: [],
      showPhone: true,
      showEmail: true,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 推送类型监听
    selType(val) {
      //console.log('推送类型', val)
      if (val == 0) {
        this.showPhone = true;
        this.showEmail = false;
      } else if (val == 1) {
        this.showPhone = false;
        this.showEmail = true;
      } else if (val == 2) {
        this.showPhone = true;
        this.showEmail = true;
      }
    },
    // 重置
    update() {
      this.searchName="";
      this.getList();
    },
    //请求列表数据
    getList() {
      this.$get("/factory/getInsectWarningSetting", {
        page: this.page,
        size: this.size,
        deviceNumber: this.searchName,
        type:0,
      }).then((res) => {
        if (res.data.state == "success") {
           this.tableData = res.data.data.datas;
          this.total = res.data.data.count;
           //console.log("获取预警列表", this.tableData)
        }
      });
    },

    // 确认提交
    saveForm(formName) {
      var datas;
      if (this.addForm.sendType!==undefined){
        let sendType = this.addForm.sendType;
        //console.log('sendType',sendType)
        let type = sendType.toString();
        // //console.log('this.addForm.sendType', this.addForm.sendType)
        datas = {
          name: this.addForm.name,
          deviceNumber: this.addForm.deviceNumber,
          earlyCount: this.addForm.earlyCount,
          sendType: type,
          phone: this.addForm.phone,
          email: this.addForm.email,
          sendContent: ''
        };
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post('/factory/addInsectAlertSetting', {
            json: JSON.stringify(datas)
          }).then((res) => {
            if (res.data.state == 'success') {
              if (res.data.data.state=='success') {
                this.$message.success('操作成功')
                this.messageBox = false
                this.getList();
              }else{
                this.$message.error(res.data.data.msg)
              }
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "添加预警信息";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
      this.getInsectDevice();
    },
    //获取虫情设备
    getInsectDevice(){
      this.$get('/insectPestStation/listInsectPestStations', {
        page: 1,
        size: 100
      }).then((res) => {
        if(res.data.state == 'success'){
          this.Lists = res.data.datas;
        }
      })
    },
    //删除
    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$get("/factory/delInsectAlertSetting", {
          id: item.id,
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.info(res.data.msg);
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
    //修改推送状态
    changeState(id) {
      this.$get('/factory/updateInsectAlertSetting', {
        id: id
      }).then((res) => {
        if (res.data.data.state == 'success') {
          this.$message.success(res.data.data.msg);
          this.getList();
        } else (
          this.$message.error(res.data.data.msg)
        )
      })
    },
// 搜索
    search() {
      // this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  }
}

</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.title /deep/ .el-form-item__content, .el-date-editor, .el-range-editor, .el-input__inner, .el-date-editor--datetimerange {
  padding: 0px 10px !important;
}

.title .upDate {
  position: absolute !important;
  right: 1vw !important;
}

.textA /deep/ .el-input__inner {
  height: 10vh;
}

.drawers /deep/ .el-drawer__header {
  margin-bottom: 4px !important;
}

.msg {
  padding: 1vh 2vw;
  font-size: 1vh;
  width: 88%;
}

.pickerBox {
  width: 90%;
  margin-left: 2vw;
}

.pickerBox /deep/ .el-input__inner {
  width: 100% !important;
  height: 30px !important;
}

.pickerBox /deep/ .el-range-separator, .pickerBox /deep/ .el-input__icon .el-range__icon .el-icon-time {
  line-height: 30px !important;
}

.msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


</style>

